/* eslint-disable react/no-unescaped-entities */

import React from 'react';

import Layout from '../components/Layout';
import { filenameToSlug } from '../helpers';

export default () => (
  <Layout title="Thank you for subscribing" slug={filenameToSlug(__filename)}>
    <h1>Thank you for subscribing!</h1>
    <p>You are now signed up to the Coding Licks newsletter. It's good to have you!</p>
  </Layout>
);
